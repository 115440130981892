/* the parent */
.slick-list {
    // padding: 0 -5px;
    // padding: 20px 0;
    // margin: -20px 0;
    // overflow: initial;
}

.slick-initialized .slick-slide, .slick-slide {
    display: flex !important;
    justify-content: center;
    padding: 0;
}

.carousel-left .slick-slide {
    justify-content: start;
}

.slick-slide {
    height: auto;
    display: flex;
}

.slick-track {
    display:flex;
}

.carousel-overflow {
    .slick-list {
        overflow: visible;
    }
    .slick-track {
        margin-left: 0;
        margin-right: 0;
    }
}
