@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
      font-family: "Maax";
      src: url("assets/fonts/Maax.otf") format("truetype");
      font-weight: 400;
      font-style: normal;
  }

  @font-face {
    font-family: "Maax";
    src: url("assets/fonts/Maax-Bold.otf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: Maax;
    src: url("assets/fonts/Maax-BoldItalic.otf") format("truetype");
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: Maax;
    src: url("assets/fonts/Maax-Italic.otf") format("truetype");
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: Maax;
    src: url("assets/fonts/Maax-Medium.otf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: Maax;
    src: url("assets/fonts/Maax-MediumItalic.otf") format("truetype");
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: Maax;
    src: url("assets/fonts/Maax-Black.otf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }
}

/* Progression gauge (lesson) */
.gauge-background {
    position: absolute;
    top: 0;
    
    stroke: black;
    stroke-width: 8;
}

.gauge {
    position: absolute;
    top: 0;
    
    stroke: red;
    stroke-width: 8;
}

.gradient {
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%);
}

/* Accordion */
@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}

/* Animate */
.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

/* Toaster */
.Toastify__progress-bar--success {
  background: #40C999;
}
/*.Toastify__toast--success {
  color: #40C999;
}*/
.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #40C999;
}

/* BottomSheet */
:root {
  --rsbs-bg: #171717;
  --rsbs-handle-bg: #323333;
}

.rich-text {
  align-items: flex-start;
}

.rich-text h1 {
  margin-bottom: 32px;
}

.rich-text  h1 {
  font-size: 34px;
  line-height: 36px; 
  weight: 500;
  letter-spacing: -0.34px;
  margin-bottom: 32px;
}

.rich-text  h2 {
  font-size: 28px;
  line-height: 33px; 
  weight: 500;
  letter-spacing: -0.28px;
  margin-bottom: 32px;
  margin-top: 56px;
}

.rich-text  h3 {
  font-size: 15px;
  line-height: 18px; 
  weight: 500;
  letter-spacing: -0.15px;
  color: #B3B3B3;
  margin-top: -16px;
}

.rich-text  figure {
  margin-top: 56px;
}

.rich-text  figcaption {
  font-size: 15px;
  line-height: 17px; 
  weight: 500;
  letter-spacing: -0.15px;
  color: #B3B3B3;
  margin-top: 16px;
}

.rich-text  blockquote {
  border-left: 4px solid #A9A9A9;
  font-style: italic;
  padding: 0 1em;
  margin: 1em;
}

.rich-text  p {
  font-size: 17px;
  line-height: 31px; 
  weight: 400;
  letter-spacing: -0.18px;
  margin-bottom: 22px;
}

.rich-text  a {
  font-size: 17px;
  line-height: 31px; 
  weight: 500;
  letter-spacing: -0.18px;
  color: #101010;
  text-decoration: underline;
}

.rich-text  ul {
  margin-bottom: 22px;
  list-style-position: inside;
  list-style: none;
}

.rich-text ul li {
  font-size: 17px;
  line-height: 31px; 
  weight: 400;
  letter-spacing: -0.18px;
}

.rich-text ul li::before {
  content: "\2022";
  color: #858585;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

.rich-text  ol {
  margin-bottom: 22px;
  list-style-position: inside;
}
.rich-text ol li {
  font-size: 17px;
  line-height: 31px; 
  font-weight: 400;
  letter-spacing: -0.18px;
}

.rich-text  img {
  max-width: 100%;
  margin-top: 24px;
}

.react-toggle-track {
  background-color: #4C4C4C !important;
}

.react-toggle .react-toggle-thumb {
  width: 16px;
  height: 16px;
  top: 4px;
  left: 4px;
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3)
}

.react-toggle--checked .react-toggle-thumb {
  left:  27px;
}
